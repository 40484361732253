<template>
  <v-layout checkout-container d-flex justify-center align-center>
    <v-layout checkout-content d-flex flex-column align-center>
      <v-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="true"
          color="blue"
        ></v-progress-linear>
        <v-card-text :style="`opacity: ${loading ? 0.6 : 1};`">
          <div class="card-layout">
            <div class="order-approved-head">
              <i class="fas fa-times-circle" style="color: red"></i>
              <h1>Pagamento não aprovado!</h1>
              <p>Valor total de <strong style="color: rgba(var(--theme-primaria));">R$ {{formatterHelper.formatMoney(checkoutValue)}}</strong></p>
              <div class="order-info">
                <h2>
                  Pedido n° <strong style="color: rgba(var(--theme-primaria));">{{ data.cart_id }}</strong> não foi processado.
                </h2>
                <p v-if="data.payment_method === 'boleto'">
                  Estamos aguardando o pagamento, a confirmação do pagamento poderá levar até 2 dias úteis.
                </p>
                <p v-else>
                  Esta doação foi reprovada pela operadora, entre em contato com o seu banco.
                </p>
              </div>
            </div>
            <slot v-if="method !== 'none'" :name="method"></slot>
          </div>
          <div class="order-approved-campaign">
            <div class="order-approved-campaign-details">
              <p>Compartilhe a campanha</p>
              <h3 style="color: rgba(var(--theme-primaria));">FLA-ANJO 2023</h3>
              <p>através das mídias sociais abaixo:</p>
            </div>
            <div class="order-approved-campaign-social">
              <a :href="GetSocialMediaLink(SocialMedia.Whatsapp, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')" target="_blank" class="social">
                <i class="fab fa-whatsapp"></i>
              </a>
              <a :href="GetSocialMediaLink(SocialMedia.Facebook, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')" target="_blank" class="social">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a :href="GetSocialMediaLink(SocialMedia.Linkedin, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')" target="_blank" class="social">
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a :href="GetSocialMediaLink(SocialMedia.Twitter, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')" target="_blank" class="social">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="box-shadow">
          <v-btn 
              :disabled="loading" 
              class="btn btn-primary outline"
              @click="gotoCampaignLP"
              :style="[!disableButton ? {'color': 'rgba(var(--theme-primaria)) !important'}: {},
                        !disableButton ? {'border-color': 'rgba(var(--theme-primaria)) !important'} : {}]"
              >
            <i class="fal fa-angle-left"></i>
            Voltar para a campanha
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" class="btn btn-primary"
            @click="gotoDashboard"
            :style="!disableButton ? {'background-color': 'rgba(var(--theme-primaria)) !important'} : {}"
          >
            Suas doações
            <i class="fal fa-angle-right"></i>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-layout>
</template>

<style scoped>
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top: 1px solid #E0E1E2 !important;
}
</style>

<script>
// VUEX
import { mapGetters } from "vuex";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";

// Enums
import { Routes } from "@/scripts/models/enums/routes.enum";
import {
  SocialMedia,
  GetSocialMediaLink
} from "@/scripts/models/enums/socialMedias.enum";

// Services
import CartService from "@/scripts/services/cart.service";

export default {
  props: ["method","data"],
  computed: {
    ...mapGetters([
      "checkoutValue",
      "cartSuccess"
    ]),
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      loading: false,
      GetSocialMediaLink: GetSocialMediaLink,      
      SocialMedia: SocialMedia,
      hasProjectIncentivized: false,
      campaign: Object,      
      cartService: new CartService(null),
    };
  },
  methods: {
    gotoCampaignLP() {
      this.$router.push({ name: Routes.app.CampaignLandingPage });
    },
    gotoDashboard() {
      this.$router.push({ name: Routes.app.DashboardPF });
    },
  },
  created() {    
    if (!this.cartSuccess) return

    const finishedCartId = this.cartSuccess.cartId;

    this.campaign = this.cartSuccess.campaign;
    
    this.cartService.getCartById(finishedCartId).then(data => {
      const haveProjects = data && data.campaign && data.campaign.campaignProjectList;

      if (!haveProjects) {
        this.hasProjectIncentivized = false;
        return;
      }

      this.hasProjectIncentivized = data.campaign.campaignProjectList.some((el) => el.project.incentiveLawId != null);
    });
    
  }
};
</script>
